/* put styles to customize and override the default theme */

.auth {
  min-height: 100vh;
}

.auth-basic {
  transform: translate(0%, 10%);

}
.page-title-bar {
  margin-bottom: 0.5rem;
}
  
.validate-feedback-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #B76BA3;
}
  
iframe {
  border: none;
}
  
.embedded-report {
  box-sizing: border-box;
  display: block;
}

.embedded-report:nth-child(1) {
  height: 75.5vh;
  width:98vw;
  padding: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
  
  /** Toast notifications **/
.Toastify__toast-container--top-right {
  top: 8em;
  right: 0.5em
}

div .colored-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: black;
  border-width: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.react-tags {
  position: relative;
  padding: 0.25rem 0 0 0.25rem;
  color: #363642;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #c6c9d5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 0.875rem;
  line-height: 1.2;
  cursor: text;
}

.react-tags.is-active {
  color: #363642;
  background-color: #ffffff;
  border-color: var(--primary);
  outline: 0;
  box-shadow: 0 0 0 1px var(--primary);
}

.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  pointer-events: none;
  cursor: not-allowed;
}

/* .react-tags.is-valid {
  border-color: #1fa774;
  box-shadow: 0 0 0 3px rgba(31, 167, 116, 0.25);
} */

.react-tags.is-invalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}

.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.react-tags__list {
  display: inline;
  padding: 0;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
}

.react-tags__tag {
  box-sizing: border-box;
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.5rem;
  border: 0;
  border-radius: 0.25rem;
  background: #eaeef2;
  font-size: 0.875rem;
  line-height: inherit;
}

.react-tags__tag:hover {
  color: #ffffff;
  background-color: var(--primary);
}

.react-tags__tag::after {
  content: '✕';
  margin-left: 0.5rem;
  font-size: 0.875rem;
}

.react-tags__tag:not(:hover)::after {
  color: #888c9b;
}

.react-tags__combobox {
  display: inline-block;

  /* match tag layout */
  padding: 0.375rem 0.25rem;
  margin-bottom: 0.25rem;

  /* prevents autoresize overflowing the container */
  max-width: 100%;
}

.react-tags__combobox-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__combobox-input::placeholder {
  color: #888c9b;
  opacity: 1;
}

.react-tags__listbox {
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);

  /* Negate the border width on the container */
  left: -2px;
  right: -2px;

  max-height: 12.5rem;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.25rem;
  background-clip: padding-box;
  border: 1px solid #c6c9d5;

  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -4px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.react-tags__listbox-no-options {
  padding: 0.375rem 0.5rem;
  font-style: italic;
}

.react-tags__listbox-option {
  padding: 0.375rem 0.5rem;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #f6f7f9;
}

.react-tags__listbox-option:not([aria-disabled='true']).is-active {
  background: var(--primary);
  color: #ffffff;
}

.react-tags__listbox-option[aria-disabled='true'] {
  color: #c6c9d5;
  cursor: not-allowed;
  pointer-events: none;
}

.react-tags__listbox-option[aria-selected='true']::after {
  content: '✓';
  margin-left: 0.5rem;
}

.react-tags__listbox-option[aria-selected='true']:not(.is-active)::after {
  color: var(--primary);
}

.allign-items-Search{
background-color: yellow;
width: 100px;
height: 40px;
position: absolute;
top: 1;
right: 0;
}
